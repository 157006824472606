
export default {
  props:{
    myStyle:{
      type: Object,
      default: {
        color: 'white'
      }
    },
    targetUrl: {
      type: String,
      default: process.env.BASE_URL
    }
  },
  methods: {
    onSubmit(){ 
      if(this.search){
        window.open(this.targetUrl + 'mb/search?keyword=' + this.search, '_self')
      } else {
        this.modalSearch = false
      }
    }
  },
  data() {
    return {
      search: '',
      modalSearch: false,
      isValidate: {
        status: true,
        message: ''
      }
    }
  }
}
