
export default {
  props: {
    format: {
      type: Array,
      default: () => ['image/jpeg', 'image/png', 'image/jpg', 'image/webp', 'image/heic', 'video/mp4', 'video/hevc']
    }
  },
  data: () => ({
    BASE_URL: process.env.BASE_API,
    imageUrl: null,
    media_file: '',
    video_url: '',
    api_url: ''
  }),
  computed: {
    openProgress () {
      const res = this.progress > 0 && this.progress < 100
      return res
    }
  },
  methods: {
    onProgress (val, file, fileList) {
      this.$emit('progress', fileList)
    },
    onSuccess (res) {
      const link = res.body && res.body.link
      this.$emit('onSuccess', link)
    },
    blobToBase64 (blob) {
      const reader = new FileReader()
      reader.readAsDataURL(blob.raw)
      return new Promise((resolve) => {
        reader.onloadend = () => {
          resolve(reader.result)
        }
      })
    },
    async handleAvatarSuccess (file) {
      const valid = await this.beforeAvatarUpload(file.raw)
      if (!valid) { return }
      const thumb = URL.createObjectURL(file.raw)
      if (file.raw && ['video/mp4', 'video/hevc'].includes(file.raw.type)) {
        this.video_url = thumb
      } else {
        this.imageUrl = thumb
      }
      this.media_file = file.raw
      this.$emit('onUpload', {
        file: this.media_file,
        thumb
      })
    },
    beforeAvatarUpload (file) {
      let isJfif = false
      if (file.name) {
        const filename = file.name.split('.')
        if (filename.length > 0 && filename[1] === 'jfif') {
          isJfif = true
        }
      }
      const isJPG = this.format.includes(file.type)
      const maxUploadSize = 2 * 1024 * 1024
      const isLt10M = file.size / maxUploadSize

      if (isJfif || !isJPG) {
        this.$notify.error({
          message: 'Format gambar tidak sesuai',
          offset: 100
        })
        return false
      }
      if (isLt10M > 1) {
        this.$notify.error({
          message: 'Ukuran maksimal gambar yang diupload adalah 2MB!',
          offset: 100
        })
        return false
      }
      return isJPG && isLt10M
    }
  }
}
